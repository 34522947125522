<template>
    <div class="box1">
        <div class="box_1">
            <van-collapse v-model="activeName" accordion>
                <van-collapse-item v-for="item in data" :key="item.id" :title="item.name" :name="item.id">
                    <p class="p_1" v-for="i in item.child" :key="i.id" @click="childBtn(item, i.id)">{{ i.title }}</p>

                </van-collapse-item>
            </van-collapse>
        </div>
        <div class="box_2">
            <p>© {{ new Date().getFullYear() }} 盛泰光电科技股份有限公司</p>
            <p> <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">渝ICP备2022000344号-5</a>
             <!-- <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50022502000557" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;margin-left: 10px;"><img src="@/assets/homePage/备案图标.png" style="float:left;margin-top: 5px;"/><p style="float:left;height:20px;line-height:20px;margin: 5px 0px 0px 5px; ">渝公网安备 50022502000557号</p></a> -->
        
            </p>

        </div>
    </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue'
import { useRouter } from "vue-router";
export default {
    emits: ['submitData'],
    setup(props, { emit }) {
        const routes = useRouter();
        watch(() => routes.currentRoute.value.path, (count, prevCount) => {
            // console.log(count);
            if (count == '/zh/product') {
                localStorage.setItem('menuId', 1)
            } else if (count == '/zh/about'|| count == '/zh/manufacture' || count == '/zh/aboutId' || count == '/zh/aboutImg' || count == '/zh/quality' || count == '/zh/research') {
                localStorage.setItem('menuId', 2)
            } else if (count == '/zh/news' || count == '/zh/news') {
                localStorage.setItem('menuId', 3)
            } else if (count == '/zh/cooperation' || count == '/zh/business_partner' || count == '/zh/commercial_ethics' || count == '/zh/social_responsibility' || count == '/zh/develop') {
                localStorage.setItem('menuId', 4)
            } else if (count == '/zh/join' || count == '/zh/joinId' || count == '/zh/idea') {
                localStorage.setItem('menuId', 5)
            } else if (count == '/zh/contactus') {
                localStorage.setItem('menuId', 6)
            }
        }, { immediate: true })
        const route = useRouter();
        const state = reactive({
            activeName: 0,
            data:  [
        { id: 1, svg: require('@/assets/foot/产品中心.svg'), name: '盛泰产品', child: [{ id: 5, title: '移动终端' }, { id: 4, title: 'NB产品' }, { id: 3, title: '智能物联网' }, { id: 2, title: '车载及医疗' },{id:1,title:"LED"}] },
        { id: 5, svg: require('@/assets/foot/加入盛泰.svg'), name: '加入盛泰', child: [{ id: 1, title: '人才招募' }, { id: 2, title: '人才理念' }] },
        { id: 3, svg: require('@/assets/foot/新闻活动.svg'), name: '盛泰新闻', child: [{ id: 1, title: '盛泰新闻' }, { id: 2, title: '盛泰活动' }] },
        { id: 2, svg: require('@/assets/foot/盛泰制造.svg'), name: '盛泰制造', child: [{ id: 1, title: '技术研发' }, { id: 2, title: '盛泰品质' }, { id: 3, title: '制造环境' }] },
      ]
        })
        let methods = {
            childBtn(item, id) {
                // alert(id)
                // console.log(item);
                localStorage.setItem('menuId', item.id)
          switch (item.id) {
          case 1:
            route.push('/zh/product')
            localStorage.setItem('id', id)
            emit('submitData', id)
            break;
          case 2:
            console.log(item);
            if(id==3){
                  localStorage.setItem('emp_id', 1)
                  route.push({ path: '/zh/manufacture' })

            }else if(id==2){
                  route.push('/zh/quality')

            }else if(id==1){
              route.push('/zh/research')

            }
            break;
          case 3:
            route.push("/zh/news");
            break;
          case 4:
            break;
          case 5:
            if(id == 1){
                // console.log(22222);
                route.push('/zh/joinId')
            }else if(id==2){
              route.push('/zh/idea')
            }
            break;

          default:
        }
                // window.scrollTo(0, 0);

            }
        }
        return {
            ...toRefs(state),
            ...methods

        }
    }
}
</script>

<style scoped lang="less">
.box1 {
    width: 100%;
    height: auto;

    .box_1 {
        width: 100%;
        min-height: 1000px;
        background-color: #F2F2F2;
    }

    .box_2 {
        width: 100%;
        background-color: #E6E6E6;
        height: 300px;
        float: left;

        p:nth-child(1) {
            margin-top: 75px;
        }

        p {
            text-align: center;
            color: black;
            font-size: 40px;

            background-color: #E6E6E6;
            margin: 0;
            padding: 0;

            a {
                cursor: pointer;
                color: black;
                text-decoration: none;
            }
        }
    }
}

::v-deep .van-cell__title {
    font-size: 55px;
    font-weight: bold;
    line-height: 220px;
    height: 200px;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    margin-left: 30px;
}

.p_1 {
    margin: 80px 50px 0px;
    height: 100px;
    font-size: 45px;
    color: black;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    width: 90%;
}

.p_1:hover {
    color: red;
}

::v-deep .van-badge__wrapper {
    font-size: 80px !important;
    margin-top: 100px;
    margin-right: 60px;

}

::v-deep .van-collapse-item__content {
    background-color: #F2F2F2;
}

::v-deep .van-cell {
    background-color: #F2F2F2;
}
</style>