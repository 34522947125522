<template>
    <div class="box2">
        <img src="@/assets/homePage/logo.svg" @click="$router.push('/')" alt="">
        <van-icon @click="right_icon" name="bars" style="color:#111;
        cursor: pointer;
        float: right;" v-if="!show" />
        <van-icon v-else @click="crossBtn" name="cross" style="color:#111;
        cursor: pointer;
        float: right;" />

    </div>
    <van-popup v-model:show="show" round class="vanPop" :overlay="false" position="right"
        :style="{ width: '100%', height: '100%', }" style="margin-top: 12rem;">
        <van-collapse accordion="false" v-model="activeNames" right-icon="fail">
            <van-collapse-item :title="item.name" v-for="item in menu_list" :key="item.id" :name="item.id"
                @click="$router.push(item.url)">
                <template #right-icon>
                    <div>
                        <van-icon name="arrow" />
                    </div>
                </template>
            </van-collapse-item>

        </van-collapse>
        <div class="lang">
            <el-icon class="el-icon--right"><img src="@/assets/homePage/lang.svg" class="SvgImg" @click="langBtn"></el-icon>
            <!-- <lang style="cursor: pointer;"></lang> -->
            <br />
            <p style="color:#666;margin-left: 5.5rem;">选择语言</p>
        </div>
    </van-popup>
    <van-popup v-model:show="show_1" round class="vanPop_1" :overlay="false" position="right"
        :style="{ width: '100%', height: '100%', }" style="margin-top: 12rem;">

        <div class="lang_box" @click="iconBtn">
            <el-icon><ArrowLeft /></el-icon>
            <p class="lang_p"> 返回上一级</p>
        </div>
        <h2 class="H2">选择区域菜单</h2>
        <div class="coll">
            <p v-for="item in langList" @click="langBtnClick(item.lang)" :key="item.id">{{ item.name }}</p>
            <p class="p_11"></p>
        </div>
    </van-popup>
</template>

<script>
import { reactive, toRefs,onMounted } from 'vue'
export default {
    components: {
    },
    setup() {

        const state = reactive({
            activeNames:[0],
            show: false,
            show_1: false,
            langList: [
                { id: 1, name: 'English', lang:'en' },
                { id: 2, name: '简体中文', lang:'zh' },
                { id: 3, name: '日本語', lang:'ja' }
            ],
            menu_list: [
                { id: 1, name: "产品中心", url: '/zh/product' },
                { id: 2, name: "关于盛泰", url: '/zh/about' },
                { id: 3, name: "新闻资讯", url: '/zh/news' },
                { id: 4, name: "合作与发展", url: '/zh/cooperation' },
                { id: 5, name: "加入我们", url: '/zh/join' },
                { id: 6, name: "联系我们", url: '/zh/contactus' },
            ]
        })
        let methods = {
            right_icon() {
                state.show = true
            },
            crossBtn() {
                state.show = false
                state.show_1 = false
            },
            langBtn() {
                state.show_1 = true
                const p = document.getElementsByClassName('lang_p')
      console.log(p[0]);
            },
            iconBtn() {
                state.show_1 = false
            },
            langBtnClick(lang) {
                state.show = false
                state.show_1 = false
                let url = window.location.href
                url = url.replace("ja", lang)
                url = url.replace("en", lang)
                url = url.replace("zh", lang)
                window.location.href = url

                localStorage.setItem('lang', lang)// 切换多语言
                //   Cookie.set('lang', lang) 
                this.$i18n.locale = lang // 设置给本地的i18n插件
                this.$message.success(this.$t('设置成功'))
          
            }
        }
        onMounted(() => {
          
            if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
              return true; // 移动端
            }else{
              return false; // PC端
            }
        }) 
        return {
            ...toRefs(state),
            ...methods
        }
    }
}
</script>

<style scoped lang="less">
h2 {
    margin-left: 6rem;
}

.coll {
    margin-top: 150px;

    p {
        height: 150px;
        width: 90%;
        line-height: 170px;
        font-size: 40px;
        cursor: pointer;
        margin: 0 auto;
        border-top: 1px solid #ccc;
    }

    .p_11 {
        height: 100px;
        width: 90%;
        margin: 0 auto;
        border-top: 1px solid #ccc;
    }
}

.SvgImg {
    width: 150px;
    background-color: white;
    cursor: pointer;
}

.lang_box {
    position: relative;
    height: auto;
}

.lang_p {
    font-size: 45px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 140px;
}

.box2 {
    position: sticky;
    top: 0;
    background-color: #fff;
    width: 100%;
    height: 200px;
    box-shadow: 2px 0px 20px rgb(0 0 0 / 20%);
    z-index: 999;

    img {
        width: 400px;
        float: left;
        margin-top: 60px;
        margin-left: 60px;
        cursor: pointer;
    }

}

.lang {
    text-align: center;
    margin-top: 300px;

    p {
        font-size: 45px;
        cursor: pointer;
        display: inline-block;
    }
}
.H2{
    font-size: 60px;
    margin-top: 100px;
}
::v-deep .van-cell__title {
    font-size: 55px;
    font-weight: bold;
    line-height: 220px;
    height: 200px;
}

::v-deep .van-badge__wrapper {
    font-size: 90px;
    margin-right: 110px;
    margin-top: 60px;
}
::v-deep .van-icon-arrow-left:before{
    margin-left: -20px !important;
}
::v-deep .el-icon{
    font-size: 60px;
    margin-top: 55px;
    margin-left: 80px;
    cursor: pointer;
}
</style>