<template>
  <div class="box">
    <el-backtop :right="40" :bottom="150" id="backTopBtn" />
    <div class="boxData">
      <div class="boxData_1">
        <div class="info" v-for="item in dataList" :key="item.id">
          <img :src="item.svg" >
          <h2 class="h2_1" >{{ item.name }}</h2>
          <p v-for="er in item.child" :key="er.id" @click="childBtn(item, er.id)">{{ er.title }}</p>
        </div>
      </div>
      <div class="boxData_2">
        <div class="text">
          <p>盛泰光电科技股份有限公司&nbsp;&nbsp;江西盛泰精密光学有限公司&nbsp;&nbsp;深圳盛泰光电科技有限公司</p>
          <P>地址: 重庆市大足区通桥街道西湖大道13号&nbsp;&nbsp;江西省新余市分宜县城东盛泰工业园&nbsp;&nbsp;深圳市龙华区华繁路嘉安达大厦2505</P>
          <p>TEL:023-85226001 023-85226002 </p>
          <p>FAX:023-85226001 023-85226002 </p>
        </div>
        <div class="image">
          <h3>扫描二维码<br />了解更多精彩内容</h3>
            <div id="qrcode_1" ref="qrcode_1" class="qrcode_1" ></div>
        </div>
      </div>
      <p class="p1">© {{new Date().getFullYear()}} 盛泰光电科技股份有限公司
       <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">渝ICP备2022000344号-5</a> 
       <a target="_blank"  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50022502000637" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;margin-left: 5px;"><img src="@/assets/homePage/备案图标.png" style="float:left;width: 15px;margin-top: 7px;"/><p style="float:left;height:20px;line-height:20px;margin: 5px 0px 0px 5px; ">渝公网安备 50022502000637号</p></a>
          </p>
   
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted ,watch,toRaw} from "vue";
import { useRouter,useRoute } from "vue-router";
import QRCode from 'qrcodejs2'
export default {
  emits: ['submitData'],

  setup(props, { emit }) {
    const route = useRouter();
    const r = useRoute()
   watch(() => route.currentRoute.value.path, (count, prevCount) => {
    // console.log(count);
      if(count=='/zh/product'){
        localStorage.setItem('menuId',1)
      }else if(count=='/zh/about' || count == '/zh/manufacture'||count=='/zh/aboutId'||count=='/zh/aboutImg' ||count=='/zh/quality'||count == '/zh/research' ){
         localStorage.setItem('menuId',2)
      }else if(count=='/zh/news'||count=='/zh/news'){
         localStorage.setItem('menuId',3)
      }else if(count == '/zh/cooperation' || count == '/zh/business_partner'|| count == '/zh/commercial_ethics'|| count == '/zh/social_responsibility'|| count == '/zh/develop'){
        localStorage.setItem('menuId',4)
      }else if(count == '/zh/join' || count=='/zh/joinId' || count=='/zh/idea'|| count=='/zh/school'){
         localStorage.setItem('menuId',5)
      }else if(count =='/zh/contactus'){
        localStorage.setItem('menuId',6)
      }
    },{ immediate: true })
    const state = reactive({
      dataList: [
        { id: 1, svg: require('@/assets/foot/产品中心.svg'), name: '盛泰产品', child: [{ id: 5, title: '移动终端' }, { id: 4, title: 'NB产品' }, { id: 3, title: '智能物联网' }, { id: 2, title: '车载及医疗' },{id:1,title:"LED"}] },
        { id: 5, svg: require('@/assets/foot/加入盛泰.svg'), name: '加入盛泰', child: [{ id: 1, title: '人才招募' }, { id: 3, title: '校园招聘'}, { id: 2, title: '人才理念' }] },
        { id: 3, svg: require('@/assets/foot/新闻活动.svg'), name: '盛泰新闻', child: [{ id: 1, title: '盛泰新闻' }, { id: 2, title: '盛泰活动' }] },
        { id: 2, svg: require('@/assets/foot/盛泰制造.svg'), name: '盛泰制造', child: [{ id: 1, title: '技术研发' }, { id: 2, title: '盛泰品质' }, { id: 3, title: '制造环境' }] },
      ]
    })
    let methods = {
            qrCode() {
        let qrcode_1 = new QRCode('qrcode_1', {
          width: 100,
          height: 100,
          text: 'https://www.shine-optics.com/zh', // 二维码地址
          colorDark: "#000",
          colorLight: "#fff",
        })
            },
      childBtn(item, id) {
        // console.log(item);
        localStorage.setItem('menuId', item.id)
        document.getElementById('backTopBtn').click()
        switch (item.id) {
          case 1:
            route.push('/zh/product')
            localStorage.setItem('id', id)
            emit('submitData', id)
            break;
          case 2:
            // console.log(item);
            if(id==3){
                  localStorage.setItem('emp_id', 1)
                  route.push({ path: '/zh/manufacture' })

            }else if(id==2){
                  route.push('/zh/quality')

            }else if(id==1){
              route.push('/zh/research')

            }
            break;
          case 3:
            route.push("/zh/news");
            break;
          case 5:
            if(id == 1){
                route.push('/zh/joinId')
            }else if(id==2){
              route.push('/zh/idea')
            }else if(id==3){
              route.push('/zh/school')
            }
            break;

          default:
        }
        // window.scrollTo(0, 0);

      }
    }
    onMounted(() => {
      // console.log('route',route.currentRoute._rawValue.path);
      methods.qrCode()
    })
    return {
      ...toRefs(state),
      ...methods
    };
  },
};
</script>

<style scoped lang="less">
.h2_1{
  font-size:18px;
}
.p1 {
  text-align: center;
  color: black;
  font-size: 15px;
  min-height: 30px;
  height: auto;
  line-height: 30px;
  background-color: #E6E6E6;
  
  margin: 0;
  padding: 0;

  a {
    cursor: pointer;
    color: black;
    text-decoration: none;
  }
}

.box {
  margin-top: 50px;
  width: 100%;
  min-height: 500px;
  background-color: #E6E6E6;
  overflow: hidden;

  .boxData {
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1920px;

    .boxData_1,
    .boxData_2 {
      flex: 1;
    }

    .boxData_1 {
      display: flex;
    }

    .boxData_2 {
      display: flex;
      .text {
        border-top: 1px solid black;
        flex: 3;
        color: black;
        padding: 30px 32px 0 50px;
        box-sizing: border-box;
      }

      .image {
        text-align: center;
        flex: 1;
        margin-left: 50px;
       
      }
    }
  }
}

.info {
  width: 100%;
  position: relative;
  text-align: center;
  min-width: 270px;

  h2 {

    margin-top: 40px;
    flex-wrap: wrap;
    margin-left: -22px;
  }

  p {
    margin-left: -30px;
    font-weight: 400;
    cursor: pointer;
    // transition: all 0.3s;
  }

  p:hover {
    color: red;
  }

  img {
    position: absolute;
    top: 35px;
    left: 90px;
    width: 40px;
    height: 40px;
  }
}
.qrcode_1{
  margin-left: 50%;
  transform: translateX(-25%);
}
</style>